/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SecurityExpertControllerService } from './services/security-expert-controller.service';
import { SecurityExpertsNewCustomersControllerService } from './services/security-experts-new-customers-controller.service';
import { SecurityExpertTeamControllerService } from './services/security-expert-team-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SecurityExpertControllerService,
    SecurityExpertsNewCustomersControllerService,
    SecurityExpertTeamControllerService,
    ApiConfiguration
  ],
})
export class CustomersSecurity_expertsModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersSecurity_expertsModule> {
    return {
      ngModule: CustomersSecurity_expertsModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersSecurity_expertsModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersSecurity_expertsModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
