/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accept } from '../fn/security-experts-new-customers-controller/accept';
import { Accept$Params } from '../fn/security-experts-new-customers-controller/accept';
import { acceptAll } from '../fn/security-experts-new-customers-controller/accept-all';
import { AcceptAll$Params } from '../fn/security-experts-new-customers-controller/accept-all';
import { getUnacceptedCustomerAssignments } from '../fn/security-experts-new-customers-controller/get-unaccepted-customer-assignments';
import { GetUnacceptedCustomerAssignments$Params } from '../fn/security-experts-new-customers-controller/get-unaccepted-customer-assignments';
import { SecurityExpertCustomerAssignmentDto } from '../models/security-expert-customer-assignment-dto';


/**
 * Provides sifa customer assignment entities. Does not do actual assignment.
 */
@Injectable({ providedIn: 'root' })
export class SecurityExpertsNewCustomersControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accept()` */
  static readonly AcceptPath = '/security-experts/customer-assignments/{assignmentId}/accept';

  /**
   * Accept customer assignment / mark assignment as recognized by sifa
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accept()` instead.
   *
   * This method doesn't expect any request body.
   */
  accept$Response(params: Accept$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityExpertCustomerAssignmentDto>> {
    return accept(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept customer assignment / mark assignment as recognized by sifa
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accept$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accept(params: Accept$Params, context?: HttpContext): Observable<SecurityExpertCustomerAssignmentDto> {
    return this.accept$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecurityExpertCustomerAssignmentDto>): SecurityExpertCustomerAssignmentDto => r.body)
    );
  }

  /** Path part for operation `acceptAll()` */
  static readonly AcceptAllPath = '/security-experts/customer-assignments/accept-all';

  /**
   * Accept customer assignments / mark assignments as recognized by sifa
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptAll$Response(params?: AcceptAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SecurityExpertCustomerAssignmentDto>>> {
    return acceptAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept customer assignments / mark assignments as recognized by sifa
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptAll(params?: AcceptAll$Params, context?: HttpContext): Observable<Array<SecurityExpertCustomerAssignmentDto>> {
    return this.acceptAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecurityExpertCustomerAssignmentDto>>): Array<SecurityExpertCustomerAssignmentDto> => r.body)
    );
  }

  /** Path part for operation `getUnacceptedCustomerAssignments()` */
  static readonly GetUnacceptedCustomerAssignmentsPath = '/security-experts/{userId}/customer-assignments/unaccepted';

  /**
   * Get all new assigned customers to a given sifa, which where not yet accepted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnacceptedCustomerAssignments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnacceptedCustomerAssignments$Response(params: GetUnacceptedCustomerAssignments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SecurityExpertCustomerAssignmentDto>>> {
    return getUnacceptedCustomerAssignments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all new assigned customers to a given sifa, which where not yet accepted.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnacceptedCustomerAssignments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnacceptedCustomerAssignments(params: GetUnacceptedCustomerAssignments$Params, context?: HttpContext): Observable<Array<SecurityExpertCustomerAssignmentDto>> {
    return this.getUnacceptedCustomerAssignments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecurityExpertCustomerAssignmentDto>>): Array<SecurityExpertCustomerAssignmentDto> => r.body)
    );
  }

}
